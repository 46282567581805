
















import Vue from 'vue'
import Component from 'vue-class-component'
import SygniRectButton from '@/components/buttons/SygniRectButton.vue';
import Utils from "@/modules/shared/utils/utils";

@Component({
  components: { SygniRectButton }
})
export default class SygniCookiesBar extends Vue {
  cookiesMessage: string = 'Dear User! <br><br>By using our platform you agree to the use of necessary cookies. We use cookies to improve the functionality and performance of the site. For more information about cookies, please see our Privacy Policy.'
  showCookies: boolean = false
  exDays: number = 7

  acceptCookies () {
    Utils.setCookie('hideCookies', '1', (this.exDays * 24 * 60 * 60 * 1000))
    this.showCookies = false
  }

  created () {
    const hideCookies = Utils.getCookie('hideCookies')
    if (!hideCookies) {
      this.showCookies = true
    }
  }
}
